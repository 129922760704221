.banner {
	padding: 70px 0;

	&__image {

		img {
			width: 100%;
			height: 320px;
			object-fit: cover;
			border-radius: 20px;
		}
	}

	a {
		cursor: pointer;
	}
}

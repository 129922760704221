.form {
	padding: 70px 0;
	width: 480px;
	margin-right: 20px;
	color: #FFFFFF;

	&__title {
		font-size: 40px;
		margin-bottom: 32px;
		cursor: default;
	}

	&__fieldset {
		margin-bottom: 32px;

		input {
			padding-left: 20px;
			margin-top: 10px;
			width: 100%;
			height: 55px;
			border: 1px solid white;
			border-radius: 30px;
		}

		&:last-of-type {
			margin-bottom: 41px;
		}
	}

	&__button {
		width: 100%;
		height: 56px;
		border-radius: 30px;
		background-color: #FFFFFF;
		color: var(--accent);
		font-size: 18px;
		transition: color .2s ease-in;

		&:hover {
			color: #7DB59A;
		}
	}
}

@media (max-width: 1300px) {
	.form {
		padding: 60px 0;
		width: 88%;
		margin-right: 0;

		&__title {
			text-align: center;
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 640px) {
	.form {
		padding: 40px 0;
		width: 83%;

		&__title {
			font-size: 29px;
			margin-bottom: 30px;
		}
	}
}

.header {

	&__row {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__info {
		
		li {
			display: inline;
			margin-right: 30px;
			font-size: 16px;
			font-weight: 400;
			color: #808080;
			cursor: pointer;

			a {
				color: #808080;
			}
		}
	}

	&__phone {
		font-size: 18px;
		font-weight: 600;
		color: #000000;
	}

	&__menu {
		display: flex;
		gap: 60px;
		list-style: none;
		cursor: pointer;

		li {
			font-size: 16px;
			font-weight: 600;

			a {
				color: #7D7A7A;
			}

			img {
				vertical-align: middle;
			}
		}
	}

	&__media {
		display: flex;
		gap: 4px;
		cursor: pointer;

		a {
			background-color: #7B8095;
			border-radius: 4px;
			display: block;
			width: 34px;
			height: 34px;
		}
	}
}

@media (max-width: 1300px) {
	.header {

		&__row {
			justify-content: flex-start;

			ul {
				display: none;
			}
		}

		&__phone {
			display: none;
		}

		&__media {
			display: none;
		}
	}	
}

@media (max-width: 640px) {
	.header__row {
		position: relative;
		
		img {
			width: 110px;
			height: 37px;
		}
	}

	.header__phone {
		display: inline;
		position: absolute;
		right: 0;
		top: 40px;
		color: #A2B9B2;
		font-size: 16px;
	}
}
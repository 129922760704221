.about {
	padding: 70px 0;
	text-align: center;
	cursor: default;

	&__title {
		font-size: 40px;
		margin-bottom: 20px;
	}

	&__description {
		font-size: 20px;
		margin-bottom: 48px;
	}

	&__info {
		text-align: end;

		img {
			position: relative;
		}
	}

	&__list-wrapper {
		position: absolute;
		z-index: 2;
		text-align: start;
		background-color: #F5F5F5;
		padding: 40px 40px 40px 60px;
		width: 559px;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		margin-top: 57px;
	}

	&__list {
		font-size: 22px;

		li {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			&:nth-child(4) {
				width: 290px;
			}
		}
	}
}

@media (max-width: 1300px) {
	.about {
		padding: 60px 0;
	
		&__title {
			display: none;
		}
	
		&__description {
			margin-bottom: 30px;
		}

		&__info {
			display: flex;
			flex-direction: column-reverse;
			gap: 30px;
	
			img {
				position: static;
			}
		}

		&__list-wrapper {
			position: static;
			width: 100%;
			border-radius: 20px;
			margin-top: 0;
		}

		&__list {
			font-size: 18px;

			li {
	
				&:nth-child(4) {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 640px) {
	.about {
		padding: 40px 0;
	
		&__description {
			font-size: 18px;
		}

		&__info {
			gap: 20px;
		}

		&__list {
			font-size: 16px;
		}
	}
}
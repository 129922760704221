.directions {
	padding: 70px 0;
	background-color: #F5F5F5;
	text-align: center;

	&__title {
		cursor: default;
		font-size: 40px;
		margin-bottom: 48px;
	}

	&__list {
		list-style: none;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(321px, 1fr));
		column-gap: 40px;
		row-gap: 40px;
	}
}

@media (max-width: 1300px) {
	.directions {
		padding: 60px 0;
	
		&__title {
			margin-bottom: 40px;
		}

		&__list {
			grid-template-columns: repeat(auto-fill, minmax(319px, 1fr));
		}
	}
}

@media (max-width: 640px) {
	.directions {
		padding: 40px 0;
	
		&__title {
			font-size: 30px;
			margin-bottom: 30px;
		}

		&__list {
			column-gap: 30px;
			row-gap: 30px;
		}
	}
}
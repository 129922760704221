.container {
	padding: 0 var(--container-padding);
	max-width: var(--container-width);
	margin: 0 auto;
}

@media (max-width: 1300px) {
	.container {
		padding: 0 44px;
	}
}

@media (max-width: 640px) {
	.container {
		padding: 0 35px;
	}
}

.container-cta {
	padding-left: calc(
		(100% - var(--container-width)) / 2
	);
}

.cta {
	background-color: var(--accent);

	&__parts {
		display: flex;
		justify-content: space-between;

		img {
			width: 62%;
		}
	}

	&__wrapper {
		padding-left: 15px;
	}
}


@media (max-width: 1300px) {
	.cta__parts {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 100%;
		}
	}

	.cta__wrapper {
		padding-left: 0;
	}
}
.card {
	position: relative;
	display: block;
	cursor: pointer;
	overflow: hidden;
	color: #FFFFFF;
	border-radius: 40px;

	&::before {
		content: "";
		z-index: 6;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 40px;
		background-color: rgba(44, 31, 19, 0.6);
	}

	img {
		position: relative;
		z-index: 5;
		border-radius: 40px;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: transform .3s linear;
	}

	&__title {
		z-index: 9;
		font-size: 26px;
		font-weight: 800;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.card:hover .card__image {
	transform: scale(1.06);
	filter: brightness(1.1);
}
.mobile-nav {
	position: fixed;
	top: -100%;
	width: 100%;
	height: 100%;
   z-index: 2;
	display: flex;
   flex-direction: column;
   align-items: center;
	justify-content: center;
   padding-top: 40px;
   padding-bottom: 40px;
	background: var(--accent);
	transition: all 0.2s ease-in;
}

.mobile-nav--open {
	top: 0;
}

.mobile-nav a {
	color: #fff;
}

.mobile-nav__list {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 20px;
	font-size: 28px;

	& .active {
		opacity: 0.5;
	}
}